import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

function NavbarEditor(props) {
    const [img, setImg] = useState("");
    const initialValue = useRef("");

    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/navbar')
            .then((res) => {
                console.log(res.data.navbar);
                setImg(res.data.navbar.img);
                initialValue.current = res.data.navbar.img;
            });

    }, []);

    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImg(reader.result);
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    function saveImage() {

    }

    return (
        <div className="nav-editor p-7">
            <form className="card-shadow rounded-lg p-7 mx-auto text-center">
                <span className="mb-3 block font-semibold text-xl">Seleccione la nueva imagen de la barra de navegación.</span>
                <div className="p-5 bg-base rounded-lg my-2">
                    <img className="mx-auto my-auto max-h-40" src={img} alt="" />
                </div>
                <input onChange={(event) => uploadFile(event)} type="file" />
                <div className="text-right mt-4">
                    <button onClick={() => setImg(initialValue.current)} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                    <button onClick={saveImage} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                </div>
            </form>
        </div>
    );
}

export { NavbarEditor }