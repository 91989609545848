import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Sidenav() {

    const [activeLink, setActiveLink] = useState(["", "", "", "", "", "", ""]);

    useEffect(() => {
        const initialUrl = window.location.href.split('/')[window.location.href.split('/').length - 1];
        showActive(initialUrl === "" ? "inicio" : initialUrl);
    }, []);

    function showActive(origin) {
        let aux = ["", "", "", "", "", "", ""];
        switch (origin) {
            case origin = "inicio":
                aux[0] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "productos":
                aux[1] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "recetas":
                aux[2] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "blog":
                aux[3] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "nosotros":
                aux[4] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "contacto":
                aux[5] = "bg-white";
                setActiveLink(aux);
                break;
            case origin = "navbar-footer":
                aux[6] = "bg-white";
                setActiveLink(aux);
                break;
            default:
                break;
        }
    }


    return (
        <div className="fixed">
            <div style={{ marginTop: "66px", height: "95vh" }} className="w-60 h-full shadow-md overflow-hidden bg-comp absolute">
                <ul className="relative">
                    <li className="relative">
                        <NavLink to={"/"}>
                            <button onClick={() => showActive("inicio")} className={activeLink[0] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Inicio</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/productos"}>
                            <button onClick={() => showActive("productos")} className={activeLink[1] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Productos</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/recetas"}>
                            <button onClick={() => showActive("recetas")} className={activeLink[2] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Recetas</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/blog"}>
                            <button onClick={() => showActive("blog")} className={activeLink[3] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Blog</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/nosotros"}>
                            <button onClick={() => showActive("nosotros")} className={activeLink[4] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Nosotros</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/contacto"}>
                            <button onClick={() => showActive("contacto")} className={activeLink[5] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Contacto</button>
                        </NavLink>
                    </li>
                    <li className="relative">
                        <NavLink to={"/navbar-footer"}>
                            <button onClick={() => showActive("navbar-footer")} className={activeLink[6] + " flex items-center text-sm w-full py-4 px-6 h-12 overflow-hidden rounded-tl-md rounded-bl-md ml-3 text-gray-700 text-ellipsis whitespace-nowrap hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out"} data-mdb-ripple="true" data-mdb-ripple-color="dark">Navbar y Footer</button>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export { Sidenav }