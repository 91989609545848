import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

function ProductEditor(props) {
    const [product, setProduct] = useState(
        {
            product: {
                name: "",
                description: "",
                img: "",
                id: 0,
                recommendations: [
                ]
            }
        }
    );
    const [imagevw, setImagevw] = useState("");
    const [isTitleValid, setIsTitleValid] = useState();
    const [isDescriptionValid, setIsDescriptionValid] = useState();

    function validateText(text) {
        let isString = false;
        if (typeof text === 'string' || text instanceof String) {
            isString = true;
        }
        if (text.length > 2 && isString) {
            return true;
        }
        return false;
    }
    const prod = useRef({
        id: 0,
        img: "",
        description: "",
        name: "",
        recommendations: [
        ]

    });

    async function submitProduct() {
        //////--------------------------------------------------------
        console.log("****** SUBMIT PRODUCT ********")
          //TODO: axios GET
          ///p4wu67s6pc nuevo
        ///xcy5iyca88 viejo
         var JSONCompleto = await
         axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/products')
            .then((res) => {
                const base = res.data.products;
                return base
            }).catch(() => {
        });
        //////--------------------------------------------------------
        
        if (product.product.id > 0) {
            //Actualiza producto
            let productCopy = product.product;
            if (prod.current.name.length > 2) {
                productCopy.name = prod.current.name;
            }
            if (prod.current.description.length > 2) {
                productCopy.description = prod.current.description;
            }
            if (imagevw.length > 0) {
                productCopy.img = prod.current.img;
            }
            console.log(productCopy);
            //////--------------------------------------------------------
           // var idABuscar = 1650856661142;
            var idABuscar = product.product.id;
            var productoEncontradoIndex = JSONCompleto.findIndex(item => item.product.id === idABuscar);
            JSONCompleto[productoEncontradoIndex]=product;
            //////--------------------------------------------------------
        } else {
            //Registra nuevo producto
            console.log(validateText(prod.current.name), validateText(prod.current.description));
            if (validateText(prod.current.name) && validateText(prod.current.description) && prod.current.img.length > 0) {
                prod.current.id = new Date().getTime();
                console.log(prod.current);
            } else {
                alert('Error:\nAsegurese de haber subido una foto y haber completado los campos requeridos.');
            }
            //////--------------------------------------------------------
            console.log("###");
            console.log(prod);
            JSONCompleto.push({"product":prod.current});
            //////--------------------------------------------------------
        }
       
        //TODO: axios POST
        const item = {
            "id" : "products",
            "products" : JSONCompleto

        }
        await axios.post(
            'https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/', 
            item,
            {
                headers: {'Content-Type': 'application/json'}
            }
        )
        .then(() => {
            alert('Item updated')
        })
        .catch('Parece que no se envió el mensaje');
    

    }

    function validate(event, origin) {
        const value = event.target.value;
        switch (origin) {
            case origin = "name":
                if (validateText(value)) {
                    setIsTitleValid();
                } else {
                    setIsTitleValid(<div className="text-red-600 text-sm">El título debe tener al menos 3 carácteres.<br />Este campo es obligatorio.</div>);
                }
                break;
            case origin = "description":
                if (validateText(value)) {
                    setIsDescriptionValid()
                } else {
                    setIsDescriptionValid(<div className="text-red-600 text-sm">La descripción debe tener al menos 3 carácteres.<br />Este campo es obligatorio.</div>);
                }
                break;

            default:
                break;
        }
    }

    function updateFromUserInput(event, origin) {
        const value = event.target.value;
        switch (origin) {
            case origin = "name":
                prod.current.name = value;
                if (validateText(value)) {
                    setIsTitleValid();
                }
                break;
            case origin = "description":
                prod.current.description = value;
                if (validateText(value)) {
                    setIsDescriptionValid();
                }
                break;

            default:
                break;
        }
        console.log(prod);
    }
    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImagevw(reader.result);
            prod.current.img = reader.result;
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    useEffect(() => {
        if (props.product !== undefined) {
            setProduct(props.product);
        }
    }, []);

    return (
        <div className="max-w-2xl rounded-md card-shadow p-10 mx-auto grid grid-cols-3">
            <div className="col-span-1">
                <img
                    id="imageView"
                    src={imagevw}
                    alt="" />
                <label className="text-center"
                >Subir una nueva imagen</label>
                <input id="image" onChange={(event) => uploadFile(event)} className="mt-2 text-sm w-full" type="file" />
            </div>
            <div className="col-span-2 flex flex-col">
                <input onBlur={(event) => validate(event, "name")} onChange={(event) => updateFromUserInput(event, "name")} defaultValue={product.product.name} className="border-solid border-gray-400 font-medium text-xl text-gray-600 border rounded-md focus-visible:outline-none p-3 w-full m-2 block" type="text" placeholder="Ingresa el título del producto" />
                {isTitleValid}
                <textarea onBlur={(event) => validate(event, "description")} onChange={(event) => updateFromUserInput(event, "description")} defaultValue={product.product.description} className="border-solid border-gray-400 text-justify text-gray-600 border rounded-md focus-visible:outline-none p-3 w-full m-2 basis-9/12" type="text" placeholder="Ingresa la descripción del producto" />
                {isDescriptionValid}
                <div className="text-right mt-4">
                    <button onClick={props.index !== undefined ? props.closeModal : () => window.location.reload()} className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                    <button onClick={submitProduct} className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                </div>
            </div>
        </div>
    )
}

export { ProductEditor }