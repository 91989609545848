import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AboutSectionEditor } from "../components/about-section-editor";
import { CallToActionEditor } from "../components/call-to-action-editor";
//Add form tag to previous forms
function About() {

    const [initialImage, setInitialImage] = useState();
    const [orgChart, setOrgChart] = useState();
    const [region, setRegion] = useState();
    const [callToAction, setCallToAction] = useState();

    const fullData = useRef();

    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/nosotros')
            .then((res) => {
                setInitialImage(res.data.nosotros.initialImage);
                setOrgChart(res.data.nosotros.orgChart);
                setRegion(res.data.nosotros.region);
                setCallToAction(res.data.nosotros.callToAction);
                fullData.current = res.data.nosotros;
                console.log(res.data.nosotros);
                console.log(fullData.current);
            });
    }, []);

    function submitFullData(changes, origin) {
        switch (origin) {
            case "initialImage":
                fullData.current.initialImage = changes;
                console.log(fullData.current);
                break;
            case "orgChart":
                fullData.current.orgChart = changes;
                console.log(fullData.current);
                break;
            case "region":
                fullData.current.region = changes;
                console.log(fullData.current);
                break;
            case "callToAction":
                fullData.current.callToAction = changes;
                console.log(fullData.current);
                break;
            default:
                break;
        }
    }

    return (// IMPORTANT If there are more than one components of the same type ex: AboutSection, is required to add a key to avoid rendering problems.
        <div className="about px-16 py-10">
            <AboutSectionEditor key={"sec1"} sectionData={initialImage} origin="initialImage" submit={submitFullData} noTitle={true} />
            <AboutSectionEditor key={"sec2"} sectionData={orgChart} origin="orgChart" submit={submitFullData} />
            <AboutSectionEditor key={"sec3"} sectionData={region} origin="region" submit={submitFullData} />
            <CallToActionEditor key={"sec4"} sectionData={callToAction} origin="callToAction" submit={submitFullData} />
        </div>
    )
}

export { About }