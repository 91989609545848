import React, { useEffect, useRef, useState } from "react";
import './css/general.css';

import { ImageTitle } from '../components/image-title';
//import { CarouselComp } from '../components/carousel';
import "../components/component-css/loader.css";
import axios from "axios";
import { HomeCertificationsEditor } from "../components/home-certifications-editor";
import { EditCertificationsModal } from "../components/edit-certifications-modal";
import { DeleteConfirmation } from "../components/delete-confirmation";
import { HomeStoresEditor } from "../components/home-stores-editor";
import { EditStoresModal } from "../components/edit-stores-modal";


//Important!! add top margin on Client side for certifications

//CRITICAL Match names from data origin.
function Home() {
    const [mainImage, setMainImage] = useState({ isActive: true, images: "" });
    const [section1, setSection1] = useState({ isActive: false, cardTitle: "", cardText: "", imageUrl: "./img/cargando.png" });
    const [section2, setSection2] = useState({ isActive: false, sectionTitle: "", articleList: [""] });

    const originalData = useRef();

    const [certificationDisplay, setCertificationDisplay] = useState(false);
    const [certModal, setCertModal] = useState();
    const [delCertModal, setDelCertModal] = useState();

    const [storeEditorDisplay, setStoreEditorDisplay] = useState(false);
    const [storeModal, setStoreModal] = useState();
    const [delStoreModal, setDelStoreModal] = useState();

    let aux;

    useEffect(() => {
        ///p4wu67s6pc nuevo
        ///xcy5iyca88 viejo
        axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/home')
            .then((res) => {
                console.log("***** Nuevo Home*****")
                console.log(res.data)
                console.log("***** Nuevo Home*****")

                const base = res.data.home;
                const sec1 = base.aboutUs;
                const sec2 = base.articlesRecent;

                /** there is an issue with useRef so for certifications I'm using local storare to 
                 * store default values.
                */
                setMainImage({ isActive: base.carrusel.isActive, images: base.carrusel.images });  //antes-> imagenInicial/ url  ahora-> carrusel/images
                setSection1(sec1);
                setSection2(sec2);
                
                originalData.current = base;
                console.log(base);
            }).catch(() => {
            });
    }, []);

    function updateMainImage(event) {
        if (event) {
            uploadFile(event).then((res) => {
                aux = { ...mainImage };
                aux.images = res;
                setMainImage(aux);
            });
        } else {
            aux = { ...mainImage };
            aux.isActive = !aux.isActive;
            setMainImage(aux);
        }
    }
   
    function updateSection1() {
        aux = { ...section1 };
        aux.isActive = !aux.isActive;
        setSection1(aux);
    }

    function updateSection2(data) {
        if (data) {
            aux = { ...section2 };
            aux.sectionTitle = data;
            setSection2(aux);
        } else {
            aux = { ...section2 };
            aux.isActive = !aux.isActive;
            setSection2(aux);
        }
    }
 
    async function uploadFile(event) {
        let promise;
        const reader = new FileReader();
        promise = new Promise((resolve, reject) => {
            reader.addEventListener("load", function () {
                resolve(reader.result);
            }, false);
        });
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
        let result = await promise;
        return result;
    }

    function submitData() {
        const inicio = {
            carrusel: mainImage,  //antes imagenInicial
            aboutUs: section1,
            articlesRecent: section2  //antes articulosRecientes
        }
        console.log(inicio);
        const item = {
            "id" : "home",   //antes inicio
            "home" : inicio

        }
        ///p4wu67s6pc nuevo
        ///xcy5iyca88 viejo
        axios.post('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/', item)
        .then(() => {
            alert('Item updated')
        })
        .catch('Parece que no se envió el mensaje');
    }

    return (
        <>
            <div>
                
                <section className="p-4">
                    <div className=" rounded-lg p-4 card-shadow">
                        <div className="flex justify-left mb-3 mt-2">
                            <div className="form-check form-switch">
                                <input checked={mainImage.isActive} onChange={() => updateMainImage()} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" />
                                <label className="form-check-label inline-block text-gray-800">{mainImage.isActive ? "Visible" : "No visible"}</label>
                            </div>
                        </div>
                        <img style={{ minHeight: "70vh" }} id="mainImg" className={"w-full h-auto"} src={mainImage.url} alt="Imagen de productos zontle" />
                        <span className="block mt-2 text-center font-semibold">Seleccione la nueva imágen.</span>
                        <input onChange={(event) => updateMainImage(event)} className="block mx-auto mt-1" type="file" />
                        <div className="text-right mt-4">
                            <button onClick={() => setMainImage(originalData.current.imagenInicial)} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                            <button onClick={submitData} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                        </div>
                    </div>
                </section>
                
                <section className="p-4">
                    <div className=" rounded-lg p-4 card-shadow">
                        <div className="flex justify-left mb-3 mt-2">
                            <div className="form-check form-switch">
                                <input checked={section1.isActive} onChange={updateSection1} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" />
                                <label className="form-check-label inline-block text-gray-800">{section1.isActive ? "Visible" : "No visible"}</label>
                            </div>
                        </div>
                        <div className={(mainImage.isActive ? "" : "pt-16 ") + "grid grid-cols-2 gap-7 cont py-6"}>
                            <div className={"bg-white border mx-3 lg:mx-14 border-gray-300 text-justify card-shadow p-8 md:p-14 rounded-md col-span-2 lg:col-span-1"}>
                                <h1 className="lg:text-4xl text-3xl text-center mb-6 text-tgray">{section1.cardTitle}</h1>
                                <p className="text-lg text-tgray">
                                    {section1.cardText}
                                </p>
                                <div className="flex space-x-2 justify-end mt-3">
                                    <button type="button" className="inline-block px-6 py-2.5 bg-orange-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-orange-600 hover:shadow-lg focus:bg-orange-600 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">Saber más</button>
                                </div>
                            </div>
                            <img className={"my-auto rounded-md col-span-2 lg:col-span-1"} src={section1.imageUrl} alt="Imagen del frente de la empresa" />
                        </div>
                        <div className="text-right mt-4">
                            <button onClick={() => setSection1(originalData.current.nosotros)} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                            <button onClick={submitData} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                        </div>
                    </div>
                </section>

                <section className="p-4">
                    <div className=" rounded-lg p-4 card-shadow">
                        <div className="flex justify-left mb-3 mt-2">
                            <div className="form-check form-switch">
                                <input checked={section2.isActive} onChange={() => updateSection2(undefined)} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" />
                                <label className="form-check-label inline-block text-gray-800">{section2.isActive ? "Visible" : "No visible"}</label>
                            </div>
                        </div>
                        <div className={"mb-11"}>
                            <input className="block my-auto mx-auto py-5 border border-gray-700 rounded-lg text-2xl mb-5 font-bold w-11/12 text-center" value={section2.sectionTitle} onChange={(event) => updateSection2(event.target.value)} />
                            <div className="flex lg:justify-around flex-col items-center lg:flex-row">
                                {
                                    section2.articleList.map((item, i) => {
                                        if (section2.articleList != "") {
                                            return (<div key={item.article.title + i} className="m-4">
                                                <ImageTitle place="home" key={item.article.title + i} title={item.article.title} img={item.article.img} />
                                            </div>)
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="text-right mt-4">
                            <button onClick={() => setSection2(originalData.current.articulosRecientes)} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                            <button onClick={submitData} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                        </div>
                    </div>
                </section>
               
            </div>
        </>
    )
}


export { Home }