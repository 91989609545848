import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

function FooterEditor() {

    const [img, setImg] = useState("");
    const [facebook, setFacebook] = useState({ isActive: false, url: "" });
    const [instagram, setInstagram] = useState({ isActive: false, url: "" });
    const [youtube, setYoutube] = useState({ isActive: false, url: "" });
    const [linkedin, setLinkedin] = useState({ isActive: false, url: "" });
    const [twitter, setTwitter] = useState({ isActive: false, url: "" });
    const [tiktok, setTiktok] = useState({ isActive: false, url: "" });
    let footer = useRef({});

    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/footer')
            .then((res) => {
                console.log(res.data.footer);
                footer.current = res.data.footer;
                setDefaultValues();
            });

    }, []);

    function setDefaultValues() {
        if (Object.values(footer.current).length > 0) {
            setFacebook(footer.current.contact.facebook);
            setInstagram(footer.current.contact.instagram);
            setYoutube(footer.current.contact.youtube);
            setLinkedin(footer.current.contact.linkedin);
            setTwitter(footer.current.contact.twitter);
            setTiktok(footer.current.contact.tiktok);
            setImg(footer.current.img);
        }
    }

    function setSelections(origin) {
        let aux;
        switch (origin) {
            case "facebook":
                aux = { ...facebook };
                aux.isActive = !aux.isActive;
                setFacebook(aux);
                break;
            case "youtube":
                aux = { ...youtube };
                aux.isActive = !aux.isActive;
                setYoutube(aux);
                break;
            case "instagram":
                aux = { ...instagram };
                aux.isActive = !aux.isActive;
                setInstagram(aux);
                break;
            case "linkedin":
                aux = { ...linkedin };
                aux.isActive = !aux.isActive;
                setLinkedin(aux);
                break;
            case "twitter":
                aux = { ...twitter };
                aux.isActive = !aux.isActive;
                setTwitter(aux);
                break;
            case "tiktok":
                aux = { ...tiktok };
                aux.isActive = !aux.isActive;
                setTiktok(aux);
                break;
            default:
                break;
        }
    }

    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImg(reader.result);
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <div className="footer-editor p-7">
            <form className="card-shadow rounded-lg p-7 mx-auto text-center">
                <span className="font-semibold my-4 block text-xl">Seleccione los enlaces que desea mostrar en el pie de página.</span>
                <div className="grid grid-cols-2 gap-7 p-5">
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Facebook:</span>
                            <div className="form-check form-switch">
                                <input checked={facebook.isActive} onChange={() => setSelections("facebook")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{facebook.url}</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Youtube:</span>
                            <div className="form-check form-switch">
                                <input checked={youtube.isActive} onChange={() => setSelections("youtube")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{youtube.url}</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Instagram:</span>
                            <div className="form-check form-switch">
                                <input checked={instagram.isActive} onChange={() => setSelections("instagram")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{instagram.url}</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Linkedin:</span>
                            <div className="form-check form-switch">
                                <input checked={linkedin.isActive} onChange={() => setSelections("linkedin")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{linkedin.url}</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Twitter:</span>
                            <div className="form-check form-switch">
                                <input checked={twitter.isActive} onChange={() => setSelections("twitter")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{twitter.url}</span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex justify-between mb-2">
                            <span className="font-semibold">Tiktok:</span>
                            <div className="form-check form-switch">
                                <input checked={tiktok.isActive} onChange={() => setSelections("tiktok")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                        <span className="text-left">{tiktok.url}</span>
                    </div>
                </div>
                <div>
                    <span className="font-semibold text-lg">Si desea cambiar la imágen del pie de página seleccione una nueva y guarde cambios.</span>
                    <div className="bg-zinc-800 p-5 rounded-lg my-3">
                        <img className="mx-auto max-h-40 " src={img} alt="" />
                    </div>
                    <input onChange={(event) => uploadFile(event)} type="file" />
                </div>
                <div className="text-right mt-4">
                    <button type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                    <button type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
                </div>
            </form>
        </div>
    );
}

export { FooterEditor }