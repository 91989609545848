import React from "react";
import './component-css/image-title.css'

function ImageTitle(props) {
    return (
        <div className="containerBox">
            <div className="textBox">
                <h3 className="p-3">{props.title}</h3>
            </div>
            <img className={(props.place === "home" ? "h-64" : "h-5/6") + " rounded-md"} src={props.img} alt={"Foto de " + props.title} />

        </div>
    );
}

export { ImageTitle };