import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const DeleteConfirmation = (props) => {
    const [open, setOpen] = useState(true);
    const onCloseModal = () => {
        setOpen(false);
        props.hide()
    };

    return (
        <div>
            <Modal open={open} onClose={onCloseModal} center>
                <h2 className="m-10 text-xl">¿Está seguro que desea borrar este elemento?</h2>
                <div className="text-right">
                    <button onClick={onCloseModal} className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                    <button className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Aceptar</button>
                </div>
            </Modal>
        </div>
    );
};

export { DeleteConfirmation }