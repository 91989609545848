import './App.css';
import { Navbar } from './components/navbar';
import { Sidenav } from './components/sidenav';
import { Home } from './views/home';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Products } from './views/products';
import { Recipes } from './views/recipes';
import { Articles } from './views/articles';
import { About } from './views/about';
import { Contact } from './views/contact';
import { NavbarFooter } from './views/navbar-footer';
import { Exp } from './components/exp';

function App() {

  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Sidenav />
        <div style={{ paddingTop: "66px" }}>
          <div className="ml-60">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/productos" element={<Products />} />
              <Route path="/recetas" element={<Recipes />} />
              <Route path="/blog" element={<Articles />} />
              <Route path="/nosotros" element={<About />} />
              <Route path="/contacto" element={<Contact />} />
              <Route path='/navbar-footer' element={<NavbarFooter />} />
              <Route path='exp' element={<Exp />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
