import axios from "axios";
import React, { useState, useEffect, useRef } from "react";

function ContactInfoEditor() {

    const [location, setLocation] = useState({ isActive: false, link: "", text: "" });
    const [officeHours, setOfficeHours] = useState({ isActive: false, text: "" });
    const [phone, setPhone] = useState({ isActive: false, link: "", text: "" });
    const [mail, setMail] = useState({ isActive: false, link: "", text: "" });
    const [facebook, setFacebook] = useState({ isActive: false, link: "", text: "" });
    const [instagram, setInstagram] = useState({ isActive: false, link: "", text: "" });
    const [youtube, setYoutube] = useState({ isActive: false, link: "", text: "" });
    // const [whatsapp, setWhatsapp] = useState({ isActive: false, link: "", text: "" });
    const [linkedin, setLinkedin] = useState({ isActive: false, link: "", text: "" });
    const [twitter, setTwitter] = useState({ isActive: false, link: "", text: "" });
    const [tiktok, setTiktok] = useState({ isActive: false, link: "", text: "" });
    let contact = useRef({});

    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/contacto')
            .then((res) => {
                contact.current = res.data.contacto;
                console.log(res.data.contacto);
                setDefaultValues();
            })
            .catch(() => {
                alert('Parece que hubo un problema al cargar la infomación.');
            });
    }, []);

    function updateInputValues(event, origin) {
        let aux;
        const value = event.target.value;
        switch (origin) {
            case "location:isActive":
                aux = { ...location };
                aux.isActive = !aux.isActive;
                setLocation(aux);
                break;
            case "location:link":
                aux = { ...location };
                aux.link = value;
                setLocation(aux);
                break;
            case "location:text":
                aux = { ...location };
                aux.text = value;
                setLocation(aux);
                break;
            case "officeHours:isActive":
                aux = { ...officeHours };
                aux.isActive = !aux.isActive;
                setOfficeHours(aux);
                break;
            case "officeHours:text":
                aux = { ...officeHours };
                aux.text = value;
                setOfficeHours(aux);
                break;
            case "phone:isActive":
                aux = { ...phone };
                aux.isActive = !aux.isActive;
                setPhone(aux);
                break;
            case "phone:link":
                aux = { ...phone };
                aux.link = value;
                setPhone(aux);
                break;
            case "phone:text":
                aux = { ...phone };
                aux.text = value;
                setPhone(aux);
                break;
            case "mail:isActive":
                aux = { ...mail };
                aux.isActive = !aux.isActive;
                setMail(aux);
                break;
            case "mail:link":
                aux = { ...mail };
                aux.link = value;
                setMail(aux);
                break;
            case "mail:text":
                aux = { ...mail };
                aux.text = value;
                setMail(aux);
                break;
            case "facebook:isActive":
                aux = { ...facebook };
                aux.isActive = !aux.isActive;
                setFacebook(aux);
                break;
            case "facebook:link":
                aux = { ...facebook };
                aux.link = value;
                setFacebook(aux);
                break;
            case "facebook:text":
                aux = { ...facebook };
                aux.text = value;
                setFacebook(aux);
                break;
            case "instagram:isActive":
                aux = { ...instagram };
                aux.isActive = !aux.isActive;
                setInstagram(aux);
                break;
            case "instagram:link":
                aux = { ...instagram };
                aux.link = value;
                setInstagram(aux);
                break;
            case "instagram:text":
                aux = { ...instagram };
                aux.text = value;
                setInstagram(aux);
                break;
            case "youtube:isActive":
                aux = { ...youtube };
                aux.isActive = !aux.isActive;
                setYoutube(aux);
                break;
            case "youtube:link":
                aux = { ...youtube };
                aux.link = value;
                setYoutube(aux);
                break;
            case "youtube:text":
                aux = { ...youtube };
                aux.text = value;
                setYoutube(aux);
                break;
            // case "whatsapp:isActive":
            //     aux = { ...whatsapp };
            //     aux.isActive = !aux.isActive;
            //     setWhatsapp(aux);
            //     break;
            // case "whatsapp:link":
            //     aux = { ...whatsapp };
            //     aux.link = value;
            //     setWhatsapp(aux);
            //     break;
            // case "whatsapp:text":
            //     aux = { ...whatsapp };
            //     aux.text = value;
            //     setWhatsapp(aux);
            //     break;
            case "linkedin:isActive":
                aux = { ...linkedin };
                aux.isActive = !aux.isActive;
                setLinkedin(aux);
                break;
            case "linkedin:link":
                aux = { ...linkedin };
                aux.link = value;
                setLinkedin(aux);
                break;
            case "linkedin:text":
                aux = { ...linkedin };
                aux.text = value;
                setLinkedin(aux);
                break;
            case "twitter:isActive":
                aux = { ...twitter };
                aux.isActive = !aux.isActive;
                setTwitter(aux);
                break;
            case "twitter:link":
                aux = { ...twitter };
                aux.link = value;
                setTwitter(aux);
                break;
            case "twitter:text":
                aux = { ...twitter };
                aux.text = value;
                setTwitter(aux);
                break;
            case "tiktok:isActive":
                aux = { ...tiktok };
                aux.isActive = !aux.isActive;
                setTiktok(aux);
                break;
            case "tiktok:link":
                aux = { ...tiktok };
                aux.link = value;
                setTiktok(aux);
                break;
            case "tiktok:text":
                aux = { ...tiktok };
                aux.text = value;
                setTiktok(aux);
                break;

            default:
                break;
        }
    }

    function setDefaultValues() {
        if (Object.values(contact.current).length > 0) {
            setLocation(contact.current.location);
            setOfficeHours(contact.current.officeHours);
            setPhone(contact.current.phone);
            setMail(contact.current.mail);
            setFacebook(contact.current.facebook);
            setInstagram(contact.current.instagram);
            setYoutube(contact.current.youtube);
            // setWhatsapp(contact.whatsapp);
            setLinkedin(contact.current.linkedin);
            setTwitter(contact.current.twitter);
            setTiktok(contact.current.tiktok);
        }
    }

    return (
        <form className="p-7 rounded-lg card-shadow m-7">
            <span className="text-xl font-semibold w-full text-center inline-block text-tgray">Información de contacto (card)</span>
            <div className="my-7 text-center">
                <span>Utilice el botón <img className="inline" src="./img/checkbox_example.png" alt="" /> para mostrar u ocultar enlaces en la card de contacto.</span>
            </div>
            <div className="grid grid-cols-2">
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Dirección física:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={location.isActive} onChange={(event) => updateInputValues(event, "location:isActive")} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300  cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={location.text} onChange={(event) => { updateInputValues(event, "location:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={location.link} onChange={(event) => { updateInputValues(event, "location:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Horario de atención:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={officeHours.isActive} onChange={(event) => { updateInputValues(event, "officeHours:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={officeHours.text} onChange={(event) => { updateInputValues(event, "officeHours:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Teléfono de contacto:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={phone.isActive} onChange={(event) => { updateInputValues(event, "phone:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={phone.text} onChange={(event) => { updateInputValues(event, "phone:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={phone.link} onChange={(event) => { updateInputValues(event, "phone:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Correo electrónico:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={mail.isActive} onChange={(event) => { updateInputValues(event, "mail:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={mail.text} onChange={(event) => { updateInputValues(event, "mail:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={mail.link} onChange={(event) => { updateInputValues(event, "mail:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Facebook:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={facebook.isActive} onChange={(event) => { updateInputValues(event, "facebook:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={facebook.text} onChange={(event) => { updateInputValues(event, "facebook:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={facebook.link} onChange={(event) => { updateInputValues(event, "facebook:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Instagram:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={instagram.isActive} onChange={(event) => { updateInputValues(event, "instagram:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={instagram.text} onChange={(event) => { updateInputValues(event, "instagram:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={instagram.link} onChange={(event) => { updateInputValues(event, "instagram:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Youtube:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={youtube.isActive} onChange={(event) => { updateInputValues(event, "youtube:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={youtube.text} onChange={(event) => { updateInputValues(event, "youtube:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={youtube.link} onChange={(event) => { updateInputValues(event, "youtube:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                {/* <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Whatsapp:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input value={whatsapp.isActive} onChange={(event) => { updateInputValues(event, "whatsapp:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={whatsapp.text} onChange={(event) => { updateInputValues(event, "whatsapp:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={whatsapp.link} onChange={(event) => { updateInputValues(event, "whatsapp:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div> */}
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Linkedin:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={linkedin.isActive} onChange={(event) => { updateInputValues(event, "linkedin:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={linkedin.text} onChange={(event) => { updateInputValues(event, "linkedin:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={linkedin.link} onChange={(event) => { updateInputValues(event, "linkedin:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Twitter:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={twitter.isActive} onChange={(event) => { updateInputValues(event, "twitter:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />                            </div>
                        </div>
                    </div>
                    <input value={twitter.text} onChange={(event) => { updateInputValues(event, "twitter:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={twitter.link} onChange={(event) => { updateInputValues(event, "twitter:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
                <div className="mt-3">
                    <div className="mx-auto w-11/12  flex justify-between">
                        <span className="text-left font-semibold text-tgray">Tiktok:</span>
                        <div className="flex justify-left mb-3">
                            <div className="form-check form-switch">
                                <input checked={tiktok.isActive} onChange={(event) => { updateInputValues(event, "tiktok:isActive") }} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 cursor-pointer shadow-sm" type="checkbox" role="switch" />
                            </div>
                        </div>
                    </div>
                    <input value={tiktok.text} onChange={(event) => { updateInputValues(event, "tiktok:text") }} placeholder="Texto" className="mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                    <input value={tiktok.link} onChange={(event) => { updateInputValues(event, "tiktok:link") }} placeholder="Url" className="mt-2 mx-auto rounded-md block w-11/12 focus-visible:outline-none border border-gray-400 p-2" type="text" />
                </div>
            </div>
            <div className="text-right mt-4">
                <button type="button" onClick={setDefaultValues} className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                <button type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
            </div>
        </form>
    );
}

export { ContactInfoEditor }