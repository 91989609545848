import axios from "axios";
import React, { useEffect } from "react";
import { FooterEditor } from "../components/footer-editor";
import { NavbarEditor } from "../components/navbar-editor";
import { Footer } from "../components/footer";

function NavbarFooter() {

    useEffect(() => {

    }, []);

    return (<div className="navbar-footer">
        <NavbarEditor />
        <FooterEditor />
        <Footer />
    </div>);
}

export { NavbarFooter }