import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { DraftEditor } from './draft-editor';

function ArticleEditor(props) {

    const [title, setTitle] = useState("");
    const [img, setImg] = useState("");
    const [content, setContent] = useState("");
    const [imageActive, setImageActive] = useState(<span className="w-full block font-semibold text-tgray text-center">Por favor de click en el botón de abajo para elegir una foto.</span>);
    const [titleValidator, setTitleValidator] = useState();
    const [contentValidator, setContentValidator] = useState();
    useEffect(() => {
        if (props.article !== undefined) {
            console.log(props.article);
            setTitle(props.article.article.title);
            setImg(props.article.article.img);
            setContent(props.article.article.content);
            setImageActive("");
        }
    }, []);

    function submitData() {
        if (props.recipe !== undefined) {
            if (content.length <= 150) {
                setContentValidator(<div className="text-sm text-red-600">El contenido es obligatorio.<br />La extensión mínima son 150 carácteres.</div>)
            }
            if (!validateText(title)) {
                setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
            }
            if (content.length >= 150 && titleValidator === undefined && img.length > 0) {
                alert('Enviar')
            } else {

                alert('Error:\nAsegurese de haber subido una foto y de haber llenado los campos solicitados.')
            }
        } else {
            if (content.length <= 150) {
                setContentValidator(<div className="text-sm text-red-600">El contenido es obligatorio.<br />La extensión mínima son 150 carácteres.</div>)
            }
            if (!validateText(title)) {
                setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
            }
            if (content.length >= 150 && titleValidator === undefined && img.length > 0) {
                alert('Enviar')
            } else {

                alert('Error:\nAsegurese de haber subido una foto y de haber llenado los campos solicitados.')
            }
        }
    }

    function updateContent(content) {
        setContent(content)
        if (content.length >= 150) {
            setContentValidator()
        } else {
        }
    }

    function updateTitle(event) {
        setTitle(event.target.value)
        if (validateText(event.target.value)) {
            setTitleValidator();
        }
    }

    function validate(event) {
        if (!validateText(event.target.value)) {
            setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
        } else {
            setTitleValidator();
        }
    }

    function validateText(text) {
        let isString = false;
        if (typeof text === 'string' || text instanceof String) {
            isString = true;
        }
        if (text.length > 2 && isString) {
            return true;
        }
        return false;
    }

    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImg(reader.result);
            setImageActive();
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    return (
        <div className="rounded-md card-shadow p-8">
            {imageActive}
            <img className="mx-auto" src={img} alt="" />
            <input onChange={(event) => uploadFile(event)} className="mx-auto my-3 block border-0" type="file" />
            <input onBlur={(event) => { validate(event) }} value={title} onChange={(event) => updateTitle(event)} className="w-full mb-5 focus-visible:outline-none border border-gray-300 p-3 font-semibold" type="text" placeholder="Título del artículo" />
            {titleValidator}
            <DraftEditor initialValue={props.article !== undefined ? props.article.article.content : ""} getEditorState={updateContent} />
            {contentValidator}
            <div className="text-right mt-4">
                <button onClick={() => window.location.reload()} className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                <button onClick={submitData} className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
            </div>
        </div>
    )
}

export { ArticleEditor }