import React, { useRef, useState } from "react";

function Exp() {
    const [objArray, setObjArray] = useState({ prop1: "XD", prop2: [""] });
    const def = useRef({ prop1: "XD", prop2: [""] });
    let aux;
    function addData() {
        aux = { ...objArray }
        aux.prop2.push("Valores");
        setObjArray(aux);
    }

    function resetData() {
        let temp = def.current
        let ob = Object.assign(temp);
        console.log(ob)
        console.log(def.current);
        setObjArray(ob);
    }
    return (
        <div>
            <h1>{objArray.prop1}</h1>
            {
                objArray.prop2.map((item, i) => {
                    return <h2 className="font-bold text-xl" key={i}>{item}</h2>
                })
            }
            <button className="p-3 bg-slate-500 mx-2 text-white" onClick={addData}>Add data</button>
            <button className="p-3 bg-slate-500 mx-2 text-white" onClick={() => resetData()}>Reset array</button>
            <button className="p-3 bg-slate-500 mx-2 text-white" onClick={() => console.log(objArray)}>showstate</button>
        </div>
    )
}

export { Exp }