import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { DraftEditor } from './draft-editor';

function RecipeEditor(props) {
    //--------------------agregué---------------------
    const [recipe, setRecipe] = useState(
        {
            recipe: {
                ingredients:{
                  title: "",
                  img:"",
                  text: "",  
                },
                instructions:{
                    title: "",
                    img: "",
                    text: "",
                },
                id: 0,
                info:{
                    title: "",
                    img: "",
                    text: "",
                }
            }
        }
    );
    const [title, setTitle] = useState("");
    const [img, setImg] = useState("");
    const [content, setContent] = useState("");
    const [imageActive, setImageActive] = useState(<span className="w-full block font-semibold text-tgray text-center">Por favor de click en el botón de abajo para elegir una foto.</span>);
    const [titleValidator, setTitleValidator] = useState();
    const [contentValidator, setContentValidator] = useState();
    const [avalibleProducts, setAvalibleProducts] = useState([]);
    const [checkboxState, setCheckboxState] = useState([]);
    const usedRecipes = useRef([]);
    const [productValidator, setProductValidator] = useState(<span className="font-semibold pt-4" >Seleccione los productos zontle usados en esta receta.</span>);
 
    
    useEffect(() => {
        if (props.recipe !== undefined) {
            setRecipe(props.recipe);
            //setTitle(props.recipe.recipe.title);
            //setImg(props.recipe.recipe.img);
            //setContent(props.recipe.recipe.content);
            //setImageActive("");
        }

    }, []);
    // TODO Add the option to mark used products

    function addUsedPRoducts(targetId, productId) {
        let aux = [...checkboxState];
        // It looks like when using arrays is necesary being pretty carefull
        // with object equivalency because same origin arrays are recognized as same array so react setstate
        // doesn't has reasons to update view.
        aux[targetId] = !checkboxState[targetId];
        setCheckboxState(aux);
        if (aux[targetId]) {
            usedRecipes.current.push(productId);
        } else {
            usedRecipes.current.forEach((item, i) => {
                if (item === productId) {
                    usedRecipes.current.splice(i, 1)
                }
            })
        }
        if (usedRecipes.current.length > 0) {
            setProductValidator();
        }
        console.log(usedRecipes.current);
    }

  async function submitData() {

    console.log("****** SUBMIT RECIPES ********")
    var JSONCompleto= await
    axios.get('https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/recipesFull')
        .then((res) => {
            setAvalibleProducts(res.data.recipesFull);
            setCheckboxState(new Array(res.data.recipesFull.length).fill(false))
        
        });


        if (props.recipe !== undefined) {
            if (content.length <= 150) {
                setContentValidator(<div className="text-sm text-red-600">El contenido es obligatorio.<br />La extensión mínima son 150 carácteres.</div>)
            }
            if (!validateText(title)) {
                setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
            }
            if (usedRecipes.current.length === 0) {
                setProductValidator(<span className="font-semibold pt-4 text-red-600" >Necesita seleccionar al menos un producto.</span>)
            }
            if (content.length >= 150 && titleValidator === undefined && img.length > 0) {
                alert('Enviar')
            } else {

                alert('Error:\nAsegurese de haber subido una foto y de haber llenado los campos solicitados.')
            }
            //---------------------------------------------------------------------
           // console.log(recipeCopy);
            //////--------------------------------------------------------
           // var idABuscar = 1650856661142;
            var idABuscar = recipe.recipe.id;
            var recipeEncontradoIndex = JSONCompleto.findIndex(item => item.recipe.id === idABuscar);
            JSONCompleto[recipeEncontradoIndex]=recipe;

            //----------------------------------------------------------
        } else {
            if (content.length <= 150) {
                setContentValidator(<div className="text-sm text-red-600">El contenido es obligatorio.<br />La extensión mínima son 150 carácteres.</div>)
            }
            if (!validateText(title)) {
                setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
            }
            if (usedRecipes.current.length === 0) {
                setProductValidator(<span className="font-semibold pt-4 text-red-600" >Necesita seleccionar al menos un producto.</span>)
            }
            if (content.length >= 150 && titleValidator === undefined && img.length > 0) {
                alert('Enviar')
            } else {

                alert('Error:\nAsegurese de haber subido una foto y de haber llenado los campos solicitados.')
            }
            console.log("###");
            console.log(prod);
            JSONCompleto.push({"recipe":prod.current});
        }
        const item = {
            "id" : "recipesFull",
            "recipesFull" : JSONCompleto  //corregir

        }
        await axios.post(
            'https://p4wu67s6pc.execute-api.us-east-1.amazonaws.com/v1/todos/', 
            item,
            {
                headers: {'Content-Type': 'application/json'}
            }
        )
        .then(() => {
            alert('Item updated')
        })
        .catch('Parece que no se envió el mensaje');
       
    }

    function updateContent(content) {
        setContent(content)
        if (content.length >= 150) {
            setContentValidator()
        } else {
        }
    }

    function updateTitle(event) {
        setTitle(event.target.value)
        if (validateText(event.target.value)) {
            setTitleValidator();
        }
    }

    function validate(event) {
        if (!validateText(event.target.value)) {
            setTitleValidator(<div className="text-sm text-red-600">El título es obligatorio.<br />Se requieren mínimo 3 carácteres.</div>);
        } else {
            setTitleValidator();
        }
    }

    function validateText(text) {
        let isString = false;
        if (typeof text === 'string' || text instanceof String) {
            isString = true;
        }
        if (text.length > 2 && isString) {
            return true;
        }
        return false;
    }
    const prod = useRef({
        ingredients:{
            title: "",
            img:"",
            text: "",  
          },
          instructions:{
              title: "",
              img: "",
              text: "",
          },
          id: 0,
          info:{
              title: "",
              img: "",
              text: "",
          }

    });

    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImg(reader.result);
            setImageActive();
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }
   

    return (
        <div className="rounded-md card-shadow p-8">
            {imageActive}
            <img className="mx-auto" src={img} alt="" />
            <input onChange={(event) => uploadFile(event)} className="mx-auto my-3 block border-0" type="file" />
            <input onBlur={(event) => { validate(event) }} value={title} onChange={(event) => updateTitle(event)} className="w-full mb-5 focus-visible:outline-none border border-gray-300 p-3 font-semibold" type="text" placeholder="Título de la receta" />
            {titleValidator}
            {productValidator}
            <div className="grid grid-cols-8 my-4">
                {
                    avalibleProducts.map((item, i) => {
                        return (<div className="" key={item.id}>
                            <input checked={checkboxState[i]} onChange={() => { addUsedPRoducts(i, item.id); console.log(checkboxState[i]) }} className="" id={item.id} type="checkbox" />
                            <label className="pl-1" >{item.name}</label>
                        </div>)
                    })
                }
            </div>
            <DraftEditor initialValue={props.recipe !== undefined ? props.recipe.recipe.content : ""} getEditorState={updateContent} />
            {contentValidator}
            <div className="text-right mt-4">
                <button onClick={() => window.location.reload()} className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                <button onClick={submitData} className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
            </div>
        </div>
    )
}

export { RecipeEditor }