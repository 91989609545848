import React, { useEffect, useState } from "react";

function CallToActionEditor(props) {

    const [title, setTitle] = useState("");
    const [btnUrl, setBtnUrl] = useState("");
    const [btnText, setBtnText] = useState("");
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (props.sectionData) {
            setTitle(props.sectionData.title);
            setBtnText(props.sectionData.btnText);
            setBtnUrl(props.sectionData.btnUrl);
            setIsActive(props.sectionData.isActive);
        }
    }, [props.sectionData]);

    function submit() {
        if (title.length > 0 && btnUrl.length > 0 && btnText.length > 0) {
            props.submit({ title, btnUrl, btnText, isActive }, props.origin);
        } else {
            alert('Error:\nTodos los campos son obligatorios.');
        }
    }

    function reset() {
        if (props.sectionData) {
            setTitle(props.sectionData.title);
            setBtnText(props.sectionData.btnText);
            setBtnUrl(props.sectionData.btnUrl);
            setIsActive(props.sectionData.isActive);
        } else {
            setTitle("");
            setBtnText("");
            setBtnUrl("");
        }
    }

    return (
        <form className="rounded-lg card-shadow p-7">
            <div className="flex justify-left mb-3">
                <div className="form-check form-switch">
                    <input onChange={() => setIsActive(!isActive)} checked={isActive} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" />
                    <label className="form-check-label inline-block text-gray-800">{isActive ? "Visible" : "No visible"}</label>
                </div>
            </div>
            <input onChange={(event) => setTitle(event.target.value)} className="text-center text-4xl focus-visible:outline-none border border-gray-300 rounded-lg p-3 w-full"
                value={title} />
            <span className="mt-4 text-xl font-semibold block text-center">Url a la que redireccionará el botón.</span>
            <input onChange={(event) => setBtnUrl(event.target.value)} className="mt-1 text-center text-4xl focus-visible:outline-none border border-gray-300 rounded-lg p-3 w-full"
                value={btnUrl} />
            <div className="flex space-x-2 justify-center my-5">
                <input onChange={(event) => setBtnText(event.target.value)} className="text-center m-5 text-2xl inline-block px-10 py-5 bg-base focus-visible:outline-none text-white font-light leading-tight uppercase rounded shadow-md"
                    value={btnText} />
            </div>
            <div className="text-right mt-4">
                <button onClick={reset} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                <button onClick={submit} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
            </div>
        </form>
    )
}

export { CallToActionEditor }