import React from "react";
import { ContactInfoEditor } from "../components/contact-info-editor";

function Contact() {
    return (
        <>
            <ContactInfoEditor />
        </>
    );
}

export { Contact }