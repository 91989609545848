import React, { useEffect, useState } from "react";
import './component-css/navbar.css'
import axios from "axios";

function Navbar() {
    const [img, setImg] = useState("");
    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/navbar')
            .then((res) => {
                setImg(res.data.navbar.img);
            });
    }, []);
    return (
        <nav className=" fixed
    w-full
    flex flex-wrap
    items-center
    justify-between
    py-2
    bg-base
    text-white
    hover:text-white
    focus:text-gray-400
    shadow-lg
    navbar navbar-expand-lg navbar-light
    ">
            <div className="container-fluid w-full flex flex-wrap items-center justify-between px-4">
                <img className="
        navbar-toggler
        text-white-500
        border-0
        hover:shadow-none hover:no-underline
        py-2
        px-2.5
        bg-transparent
        focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
      " style={{ maxWidth: "60px" }} src={img} alt="" />

                <div className="collapse navbar-collapse flex-grow items-center grid grid-cols-2 justify-between" id="navbarSupportedContent">

                    <div>
                        <img id="lgDisplay" src={img} style={{ height: "50px" }} alt=""
                            loading="lazy" />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export { Navbar }