import React, { useState } from "react";
import { ListItemEditRecipes } from "../components/list-item-edit-recipes";
import { RecipeEditor } from "../components/recipe-editor";

function Recipes() {
    const [visibility, setVisibility] = useState("hidden");
    function changeVisibility() {
        visibility === "hidden" ? setVisibility("block") : setVisibility("hidden");
    }
    return (
        <div className="text-center my-6">
            <button className="inline-block px-6 py-2.5 bg-gray-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out" onClick={changeVisibility}>Añadir receta</button>
            <div className={visibility + " rounded-md my-6 mx-14"}>
                <RecipeEditor visibility={changeVisibility} />
            </div>
            <div className="mt-10">
                <ul className="">
                    <ListItemEditRecipes />
                </ul>
            </div>
        </div>
    )
}

export { Recipes }