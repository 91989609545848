import axios from "axios";
import React, { useEffect, useState } from "react";
import { DeleteConfirmation } from "./delete-confirmation";
import { ArticleModal } from "./article-modal";

function ListItemEditArticles() {
    // TODO add creation date to articles when sent
    let [article, setArticle] = useState([]);
    useEffect(() => {
        axios.get('https://xcy5iyca88.execute-api.us-east-1.amazonaws.com/v1/todos/articulosCompletos')
            .then((res) => {
                setArticle(res.data.articulosCompletos);
                console.log(res.data.articulosCompletos);
            });
    }, []);
    const [modal, setModal] = useState("");
    const [delConfirm, setDelConfirm] = useState("");
    const hideModal = () => {
        setModal("");
    }
    const hideDelConf = () => {
        setDelConfirm("");
    }
    function showDelConf(index) {
        setDelConfirm(<DeleteConfirmation index={index} hide={hideDelConf} />);
    }
    function showModal(index) {
        if (modal === "") {
            setModal(<ArticleModal article={article[index]} index={index} mod={hideModal} />);
        }
    };
    return (
        <div>
            <ul>
                {
                    article.map((item, i) => {
                        return <li id={i} key={item.article.id} className="max-w-2xl mx-auto mb-2">
                            <div className="flex border card-shadow text-gray-600 rounded-lg">
                                <div className="basis-10/12 p-2 text-left font-semibold">{item.article.title}
                                </div>
                                <div className="basis-1/12 p-2 flex justify-center">
                                    <button onClick={() => showModal(i)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.003" height="18.002" viewBox="0 0 18.003 18.002">
                                            <path id="ic_create_24px" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" transform="translate(-3 -2.998)" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="basis-1/12 p-2 flex justify-center">
                                    <button onClick={() => showDelConf(i)}>
                                        <svg className="my-auto" fill="#aa0000" xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                            <path id="ic_delete_forever_24px" d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6Zm2.46-7.12,1.41-1.41L12,12.59l2.12-2.12,1.41,1.41L13.41,14l2.12,2.12-1.41,1.41L12,15.41,9.88,17.53,8.47,16.12,10.59,14ZM15.5,4l-1-1h-5l-1,1H5V6H19V4Z" transform="translate(-5 -3)" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    })
                }
            </ul>
            {modal}
            {delConfirm}
        </div>
    )
}

export { ListItemEditArticles }