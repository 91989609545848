import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { RecipeEditor } from './recipe-editor';

const RecipeModal = (props) => {
    const [open, setOpen] = useState(true);
    const onCloseModal = () => {
        setOpen(false);
        props.mod()
    };

    return (
        <div>
            <Modal open={open} onClose={onCloseModal} center>
                <RecipeEditor recipe={props.recipe} closeModal={onCloseModal} index={props.index} />
            </Modal>
        </div>
    );
};

export { RecipeModal }