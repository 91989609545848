import axios from "axios";
import React, { useEffect, useState } from "react";

function AboutSectionEditor(props) {
    const [img, setImg] = useState("");
    const [text, setText] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [title, setTitle] = useState("");
    useEffect(() => {
        if (props.sectionData) {
            setImg(props.sectionData.img);
            setText(props.sectionData.text);
            setIsActive(props.sectionData.isActive);
            if (props.sectionData.title) {
                setTitle(props.sectionData.title);
            }
        }
    }, [props.sectionData]);

    function submit() {
        if (img.length > 0 && (text.length > 0 || title.length > 0)) {
            let changes = { isActive, img, text };
            if (!props.noTitle) {
                changes.title = title;
            }
            props.submit(changes, props.origin);
        } else {
            alert('Error:\nLa foto es obligatoria.\nSe requiere que rellene el título o la descripción.')
        }
    }

    function reset() {
        if (props.sectionData) {
            setImg(props.sectionData.img);
            setText(props.sectionData.text);
            setIsActive(props.sectionData.isActive);
            if (props.sectionData.title) {
                setTitle(props.sectionData.title);
            }
        } else {
            setImg("");
            setText("");
            setIsActive("");
            setTitle("");
        }
    }

    function uploadFile(event) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            setImg(reader.result);
        }, false);
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    return (
        <form className="card-shadow mb-10 p-7 rounded-lg" >
            <div className="flex justify-left mb-3">
                <div className="form-check form-switch">
                    <input onChange={() => setIsActive(!isActive)} checked={isActive} className="checked:bg-comp form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" type="checkbox" role="switch" />
                    <label className="form-check-label inline-block text-gray-800">{isActive ? "Visible" : "No visible"}</label>
                </div>
            </div>
            {!props.noTitle ? <input className="focus-visible:outline-none w-full text-center font-semibold text-2xl block my-3 border border-gray-300 rounded-lg p-2" type="text" value={title} onChange={(event) => setTitle(event.target.value)} /> : null}
            <img className="mb-3" src={img} alt="" />
            <input onChange={(event) => uploadFile(event)} className="mx-auto block" type="file" />
            <br />
            <textarea onChange={(event) => setText(event.target.value)} value={text} rows="8" className="overflow-visible p-3 w-full focus-visible:outline-none border border-gray-300 rounded-lg" name="section1" id="s1"></textarea>
            <div className="text-right mt-4">
                <button onClick={reset} type="button" className="mr-4 inline-block px-6 py-2 border-2 border-gray-600 text-gray-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">Cancelar</button>
                <button onClick={submit} type="button" className="inline-block px-6 py-2.5 bg-gray-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-700 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out">Guardar</button>
            </div>
        </form>
    )
}

export { AboutSectionEditor }